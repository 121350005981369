<template>
    <dialog v-if="modal" ref="modal" class="modal modal-bottom sm:modal-middle">
        <form method="dialog" class="modal-backdrop">
            <button>{{ $t('labels.close') }}</button>
        </form>
        <div class="modal-box modal-wide">
            <form method="dialog">
                <button
                    class="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
                    :aria-label="$t('labels.close')"
                >
                    ✕
                </button>
            </form>

            <h3 class="font-bold h5">{{ title }}</h3>

            <p v-if="message" class="mb-4">{{ message }}</p>

            <Link
                v-if="ctaUrl"
                :href="ctaUrl"
                class="btn btn-primary btn-block"
                as-button
            >
                {{ ctaText }}
            </Link>
        </div>
    </dialog>
</template>

<script>
import { Link } from '@inertiajs/vue3';

export default {
    components: {
        Link,
    },
    props: {
        modal: {
            type: Object,
        },
    },
    emits: ['hide'],
    computed: {
        title() {
            return this.modal?.title;
        },
        message() {
            return this.modal?.message;
        },
        ctaText() {
            return this.modal?.ctaText;
        },
        ctaUrl() {
            return this.modal?.ctaUrl;
        },
    },
    mounted() {
        this.$refs.modal?.showModal();

        this.$refs.modal?.addEventListener('close', this.onClose);
    },
    beforeUnmount() {
        this.$refs.modal?.close();

        this.$refs.modal?.removeEventListener('close', this.onClose);
    },
    methods: {
        onClose() {
            this.$emit('hide');
        },
    },
};
</script>
