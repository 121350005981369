<template>
    <div class="fixed top-0 right-0 flex justify-end w-full mt-16">
        <Toast
            v-if="success"
            type="success"
            :title="$t('labels.success')"
            :message="success"
            @hide="onHideSuccess"
        />
        <Toast
            v-if="info"
            type="info"
            :title="$t('labels.info')"
            :message="info"
            @hide="onHideInfo"
        />
        <Toast
            v-if="warning"
            type="warning"
            :title="$t('labels.warning')"
            :message="warning"
            @hide="onHideWarning"
        />
        <Toast
            v-if="error"
            type="error"
            :title="$t('labels.error')"
            :message="error"
            @hide="onHideError"
        />
        <Toast
            v-if="status"
            :title="$t('labels.status')"
            :message="status"
            @hide="onHideStatus"
        />
    </div>
    <InfoModal v-if="modal" :modal="modal" @hide="onHideModal" />
</template>

<script>
import Toast from '@/Components/Toast.vue';
import InfoModal from './InfoModal.vue';
import { router, usePage } from '@inertiajs/vue3';

export default {
    name: 'FlashMessages',
    components: {
        Toast,
        InfoModal,
    },
    data() {
        const flash = usePage().props.flash;

        return {
            success: flash.success || null,
            info: flash.info || null,
            warning: flash.warning || null,
            error: flash.error || null,
            status: flash.status || null,
            modal: flash.modal || null,
        };
    },
    computed: {
        flash() {
            return usePage().props.flash;
        },
    },
    watch: {
        flash: {
            handler({ success, info, warning, error, status, modal }) {
                this.success = success;
                this.info = info;
                this.warning = warning;
                this.error = error;
                this.status = status;
                this.modal = modal;
            },
            deep: true,
        },
    },
    methods: {
        reloadFlash() {
            router.reload({ only: ['flash'] });
        },
        onHideSuccess() {
            this.success = null;
            this.reloadFlash();
        },
        onHideInfo() {
            this.info = null;
            this.reloadFlash();
        },
        onHideWarning() {
            this.warning = null;
            this.reloadFlash();
        },
        onHideError() {
            this.error = null;
            this.reloadFlash();
        },
        onHideStatus() {
            this.status = null;
            this.reloadFlash();
        },
        onHideModal() {
            this.modal = null;
            this.reloadFlash();
        },
    },
};
</script>
