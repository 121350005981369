<template>
    <dialog ref="modal" class="modal modal-bottom sm:modal-middle">
        <form method="dialog" class="modal-backdrop">
            <button>{{ $t('labels.close') }}</button>
        </form>
        <div class="modal-box modal-wide">
            <form method="dialog">
                <button
                    class="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
                    :aria-label="$t('labels.close')"
                >
                    ✕
                </button>
            </form>

            <h3 class="font-bold h5">{{ $t('labels.tutorial') }}</h3>

            <div v-if="pageTutorial && !isClosed" class="my-8">
                <video
                    v-if="isVideoSelfHosted"
                    class="w-full h-auto aspect-4/3"
                    controls
                >
                    <source :src="pageTutorial.videoUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <iframe
                    v-else
                    class="w-full h-auto aspect-video"
                    :src="pageTutorial.videoUrl"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>

            <form @submit.prevent="submit">
                <button class="btn btn-primary btn-block">
                    {{ $t('labels.dont_show_again') }}
                </button>
            </form>
        </div>
    </dialog>
</template>

<script>
import { usePage, router } from '@inertiajs/vue3';

export default {
    data() {
        return {
            isClosed: false,
        };
    },
    computed: {
        pageTutorial() {
            return usePage().props.pageTutorial;
        },
        isVideoSelfHosted() {
            return this.pageTutorial?.videoUrl.includes('storage');
        },
    },
    watch: {
        pageTutorial: {
            handler(newValue) {
                this.isClosed = false;

                if (this.shouldShowModal(newValue)) {
                    this.$refs.modal.showModal();
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.shouldShowModal(this.pageTutorial)) {
            this.$refs.modal.showModal();
        }

        if (this.$refs.modal) {
            this.$refs.modal.addEventListener('close', this.onClose);
        }
    },
    beforeUnmount() {
        if (this.$refs.modal) {
            this.$refs.modal.removeEventListener('close', this.onClose);
        }
    },
    methods: {
        submit() {
            router.post(
                route('page-tutorials.mark-as-seen', {
                    page: this.pageTutorial.page,
                }),
                {},
                {
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => this.$refs.modal?.close(),
                },
            );
        },
        onClose() {
            this.isClosed = true;
        },
        shouldShowModal(pageTutorial) {
            if (!this.$refs.modal) {
                return false;
            }

            if (this.isClosed) {
                return false;
            }

            if (!pageTutorial) {
                return false;
            }

            return pageTutorial.videoUrl;
        },
    },
};
</script>
