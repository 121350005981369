<template>
    <nav class="fixed top-0 z-30 w-full bg-base-100">
        <div v-if="showNav" class="px-4 navbar md:px-8">
            <div class="flex-none">
                <Link class="btn btn-ghost" :href="route('index')">
                    <Logo class="w-auto h-12" />
                </Link>
            </div>
            <div class="flex-1 gap-4 navbar-end">
                <div class="dropdown dropdown-end lg:hidden">
                    <div tabindex="0" role="button" class="m-1 btn">
                        <HamburgerMenuIcon class="w-8 h-8" />
                    </div>
                    <ul
                        tabindex="0"
                        class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-screen -mx-4 md:-mx-8"
                    >
                        <Link
                            v-if="isLoggedIn"
                            class="mb-4 btn btn-primary btn-block"
                            :href="route('offers.search-offer-products')"
                            @click="blurActiveElement"
                        >
                            {{ $t('navigation.place_offer') }}
                        </Link>
                        <Link
                            v-else
                            :href="route('dashboard.index')"
                            class="mb-4 btn btn-primary btn-block"
                            @click="blurActiveElement"
                        >
                            <UserIcon class="w-4 h-4" />
                            {{ $t('navigation.get_started') }}
                        </Link>
                        <ul class="text-lg menu">
                            <li>
                                <Link
                                    :href="route('marketplace.index')"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.discover_sneakers') }}
                                </Link>
                            </li>
                            <li v-if="!isLoggedIn">
                                <Link
                                    :href="route('pricing')"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.pricing') }}
                                </Link>
                            </li>
                        </ul>
                        <ul v-if="isLoggedIn" class="text-lg menu">
                            <li>
                                <Link
                                    :href="route('dashboard.index')"
                                    :class="{
                                        active:
                                            currentRoute ===
                                            route('dashboard.index'),
                                    }"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.dashboard') }}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('offers.index')"
                                    :class="{
                                        active:
                                            currentRoute ===
                                            route('offers.index'),
                                    }"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.my_offers') }}
                                    <div
                                        v-if="user.stats.numberOfOpenOffers"
                                        class="badge badge-primary"
                                    >
                                        {{ user.stats.numberOfOpenOffers }}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('orders.index')"
                                    :class="{
                                        active:
                                            currentRoute ===
                                            route('orders.index'),
                                    }"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.active_orders') }}
                                    <div
                                        v-if="user.stats.numberOfOpenOrders"
                                        class="badge badge-primary"
                                    >
                                        {{ user.stats.numberOfOpenOrders }}
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('history.index')"
                                    :class="{
                                        active:
                                            currentRoute ===
                                            route('history.index'),
                                    }"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.history') }}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('chats.index')"
                                    :class="{
                                        active:
                                            currentRoute ===
                                            route('chats.index'),
                                    }"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.chats') }}
                                    <div
                                        v-if="user.stats.numberOfUnreadMessages"
                                        class="badge badge-primary"
                                    >
                                        {{ user.stats.numberOfUnreadMessages }}
                                    </div>
                                </Link>
                            </li>
                        </ul>
                        <ul v-if="isLoggedIn" class="text-lg menu">
                            <li>
                                <Link
                                    :href="route('settings.edit')"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.settings') }}
                                </Link>
                            </li>
                            <li v-if="user.isAdmin">
                                <Link :href="route('nova.pages.home')">
                                    {{ $t('navigation.admin_dashboard') }}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    :href="route('logout')"
                                    method="post"
                                    as="button"
                                    @click="blurActiveElement"
                                >
                                    {{ $t('navigation.logout') }}
                                </Link>
                            </li>
                        </ul>
                    </ul>
                </div>

                <ul
                    class="hidden gap-8 px-1 font-bold lg:flex menu menu-horizontal"
                >
                    <li>
                        <Link :href="route('marketplace.index')">
                            {{ $t('navigation.discover_sneakers') }}
                        </Link>
                    </li>
                    <li v-if="isLoggedIn">
                        <Link :href="route('dashboard.index')">
                            {{ $t('navigation.my_profile') }}
                        </Link>
                    </li>
                    <li v-else>
                        <Link :href="route('pricing')">
                            {{ $t('navigation.pricing') }}
                        </Link>
                    </li>
                </ul>
                <Link
                    v-if="isLoggedIn"
                    class="hidden lg:flex btn btn-primary"
                    :href="route('offers.search-offer-products')"
                >
                    {{ $t('navigation.place_offer') }}
                </Link>
                <Link
                    v-else
                    :href="route('dashboard.index')"
                    class="hidden lg:flex btn btn-primary"
                >
                    <UserIcon class="w-4 h-4" />
                    {{ $t('navigation.get_started') }}
                </Link>
            </div>
        </div>
        <div v-else class="flex justify-center navbar">
            <Link class="btn btn-ghost" :href="route('index')">
                <Logo class="w-auto h-12" />
            </Link>
        </div>
    </nav>
</template>

<script>
import { Link, usePage } from '@inertiajs/vue3';
import UserIcon from '@assets/icons/User.svg?component';
import HamburgerMenuIcon from '@assets/icons/List.svg?component';
import Logo from '@assets/logos/text_white_font_transparent.svg?component';

export default {
    name: 'Navbar',
    components: { Link, UserIcon, Logo, HamburgerMenuIcon },
    computed: {
        user() {
            return usePage().props.auth.user;
        },
        lockPages() {
            return usePage().props.config.lockPages;
        },
        isLoggedIn() {
            return !!this.user;
        },
        showNav() {
            if (this.lockPages) {
                return this.user;
            }

            return true;
        },
        currentRoute() {
            return usePage().props.ziggy.location;
        },
    },
    methods: {
        blurActiveElement() {
            document.activeElement.blur();
        },
    },
};
</script>
