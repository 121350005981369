<template>
    <Head>
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            :href="`/apple-touch-icon-${isDarkTheme ? 'dark' : 'light'}.png`"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            :href="`/favicon-32x32-${isDarkTheme ? 'dark' : 'light'}.png`"
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            :href="`/favicon-16x16-${isDarkTheme ? 'dark' : 'light'}.png`"
        />
        <link
            rel="manifest"
            :href="`/site-${isDarkTheme ? 'dark' : 'light'}.webmanifest`"
        />
    </Head>
    <slot />
    <FlashMessages />
    <PageTutorialModal />
    <InfoModal />
</template>

<script>
import { usePage, router, Head } from '@inertiajs/vue3';
import { pagePropsIncludes } from '@/helpers';
import FlashMessages from '@/Layouts/Partials/FlashMessages.vue';
import PageTutorialModal from '@/Layouts/Partials/PageTutorialModal.vue';
import InfoModal from '@/Layouts/Partials/InfoModal.vue';

export default {
    name: 'BaseLayout',
    components: {
        Head,
        FlashMessages,
        PageTutorialModal,
        InfoModal,
    },
    data() {
        return {
            isDarkTheme:
                window.matchMedia('(prefers-color-scheme: dark)')?.matches ||
                false,
        };
    },
    computed: {
        pageProps() {
            return usePage().props;
        },
        user() {
            return usePage().props.auth.user;
        },
    },
    watch: {
        user(newUser, oldUser) {
            this.unsubscribeFromUserChannel(oldUser);
            this.subscribeToUserChannel(newUser);
        },
    },
    mounted() {
        this.subscribeToUserChannel(this.user);
        this.addThemeEventListener();
    },
    beforeUnmount() {
        this.unsubscribeFromUserChannel(this.user);
        this.removeThemeEventListener();
    },
    methods: {
        setTheme(e) {
            this.isDarkTheme = e.matches;
        },
        addThemeEventListener() {
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', this.setTheme);
        },
        removeThemeEventListener() {
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .removeEventListener('change', this.setTheme);
        },
        subscribeToUserChannel(user) {
            if (!user) {
                return;
            }

            window.Echo.private('users.' + user.sqid)
                .listen('OrderCreated', (event) => this.updateOrderStats(event))
                .listen('OrderStatusUpdated', (event) =>
                    this.updateOrderStats(event),
                )
                .listen('OrderMessageCreated', (event) =>
                    this.updateMessageStats(event),
                );
        },
        unsubscribeFromUserChannel(user) {
            if (!user) {
                return;
            }

            window.Echo.private('users.' + user.sqid)
                .stopListening('OrderCreated')
                .stopListening('OrderStatusUpdated')
                .stopListening('OrderMessageCreated');
        },
        updateOrderStats(event) {
            this.user.stats.numberOfOpenOffers =
                event.receiver.stats.numberOfOpenOffers;

            this.user.stats.numberOfOpenOrders =
                event.receiver.stats.numberOfOpenOrders;

            if (
                pagePropsIncludes(this.pageProps, [
                    'offers',
                    'orders',
                    'history',
                    'stats',
                ])
            ) {
                router.reload({
                    only: ['offers', 'orders', 'history', 'stats'],
                });
            }
        },
        updateMessageStats(event) {
            this.user.stats.numberOfUnreadMessages =
                event.receiver.stats.numberOfUnreadMessages;

            if (pagePropsIncludes(this.pageProps, ['chats'])) {
                router.reload({ only: ['chats'] });
            }
        },
    },
};
</script>
